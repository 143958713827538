import { useAuth } from '@upper/auth'
import { buttonVariants, cn } from '@upper/sapphire/ui'
import { Text } from '@upper/ui'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { PropsWithChildren, useMemo } from 'react'
import wcmatch from 'wildcard-match'
import { MainNavigationLayer } from '../../components/main-navigation-layer'
import { MainLayout } from '../../fragments/layout/main'
import type { Props } from './context'
import { Context } from './context'
import { usePathname } from 'next/navigation'

export const Provider = ({ children, ...props }: PropsWithChildren<Props>) => {
  const pathname = usePathname()
  const { userHasRole } = useAuth()

  const allPassed = useMemo(() => {
    const path = pathname
    return !props.restrict.some((r) => {
      const isMatch = wcmatch(r.path)
      return isMatch(path) && userHasRole(r.forRoles)
    })
  }, [props.restrict, pathname, userHasRole])

  if (!allPassed) {
    return (
      <Context.Provider value={props}>
        <MainLayout>
          <MainNavigationLayer>
            <section className="p-10">
              <Text variant="title">Access Denied</Text>
              <Text variant="subtitle">
                You do not have access to this page
              </Text>
              <Link href="/" className={cn('mt-5', buttonVariants({}))}>
                Home
              </Link>
            </section>
          </MainNavigationLayer>
        </MainLayout>
      </Context.Provider>
    )
  } else {
    return <Context.Provider value={props}>{children}</Context.Provider>
  }
}
