'use client'

import { useAuth } from '@upper/auth'
import { RoleKey } from '@upper/graphql/internal'
import { useModals } from '@upper/hooks'
import { ContactPlusIcon, FilePlusIcon, Logo, StaffingIcon } from '@upper/icons'
import { classNames } from '@upper/ui'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import {
  ButtonHTMLAttributes,
  PropsWithChildren,
  ReactNode,
  useCallback,
} from 'react'
import {
  EngagementsIcon,
  JobsIcon,
  TalentsIcon,
  TimesheetsIcon,
} from '../components/icons'
import { ModalAction } from '../const/modals'
import { useNavigation } from '../hooks/use-navigation'

export function MainNavigation() {
  const { user, userHasRole, logout } = useAuth()
  const { show: showAddTalent, hide: hideAddTalent } = useModals(
    ModalAction.ADD_TALENT
  )
  const { show: showAddJob, hide: hideAddJob } = useModals(ModalAction.ADD_JOB)
  const { size, condensed } = useNavigation()

  const handleAddTalent = useCallback(() => {
    hideAddJob()
    showAddTalent()
  }, [hideAddJob, showAddTalent])

  const handleShowAddJob = useCallback(() => {
    hideAddTalent()
    showAddJob()
  }, [hideAddTalent, showAddJob])

  const canAccessEngagements = !userHasRole([RoleKey.ExternalExpert])
  const canAccessTimesheets = !userHasRole([RoleKey.ExternalExpert])

  return (
    <header
      aria-controls="Main Menu"
      className="h-full overflow-hidden rounded-lg"
      style={{
        width: size,
        background:
          'linear-gradient(99.42deg, #0F44D7 17%, #002AB6 170.64%), linear-gradient(0deg, #FFFFFF, #FFFFFF), #FFFFFF',
        boxShadow: '0px 11px 23px rgba(70, 84, 122, 0.43)',
        transition: 'all 300ms ease',
      }}
    >
      {/* nav */}
      <button
        className="absolute -right-4 top-6 w-4 rounded-r-md bg-black bg-opacity-30 px-1"
        onClick={condensed.toggle}
        style={{ height: 30 }}
      >
        <svg
          width="6"
          height="11"
          viewBox="0 0 6 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          transform={condensed.value ? '' : 'rotate(180)'}
        >
          <path
            d="M0.5625 0.414062L0.09375 0.859375C0 0.976562 0 1.16406 0.09375 1.25781L4.33594 5.5L0.09375 9.76562C0 9.85938 0 10.0469 0.09375 10.1641L0.5625 10.6094C0.679688 10.7266 0.84375 10.7266 0.960938 10.6094L5.88281 5.71094C5.97656 5.59375 5.97656 5.42969 5.88281 5.3125L0.960938 0.414062C0.84375 0.296875 0.679688 0.296875 0.5625 0.414062Z"
            fill="white"
          />
        </svg>
      </button>
      <nav className="mt-3">
        <Link
          href="/"
          className="block min-w-0 overflow-hidden px-4 py-4 transition-all"
          style={{ width: condensed.value ? 44 : 180 }}
        >
          <Logo className="text-white" height={30} width={144} />
        </Link>
        <ul className="mt-6 px-2">
          <NavigationLink label="Jobs" icon={<JobsIcon />}>
            Jobs
          </NavigationLink>
          <NavigationLink
            label="Staffing"
            url="/staffing"
            icon={<StaffingIcon />}
          >
            Staffing
          </NavigationLink>
          {canAccessEngagements && (
            <NavigationLink
              label="Engagements"
              icon={<EngagementsIcon />}
              url="/engagements"
            >
              Engagements
            </NavigationLink>
          )}
          <NavigationLink
            label="Freelancers"
            icon={<TalentsIcon />}
            url="/freelancers"
          >
            Freelancers
          </NavigationLink>
          {canAccessTimesheets && (
            <NavigationLink
              label="Timesheets"
              icon={<TimesheetsIcon />}
              url="/timesheets"
            >
              Timesheets
            </NavigationLink>
          )}
        </ul>
        <ul
          className={classNames(
            'mx-2 mt-4 border-b border-t border-white py-4',
            'transition-all duration-300',
            condensed.value ? 'border-opacity-20' : 'border-opacity-0'
          )}
        >
          <NavigationButton
            label="Add job"
            icon={<FilePlusIcon />}
            onClick={handleShowAddJob}
          >
            Add job
          </NavigationButton>
          {canAccessEngagements && (
            <NavigationButton
              label="Add talent"
              icon={<ContactPlusIcon />}
              onClick={handleAddTalent}
            >
              Add talent
            </NavigationButton>
          )}
          {/* <NavigationButton label="Add client" icon={<ReferIcon />} disabled>
            Add client
          </NavigationButton> */}
        </ul>
        <div className="absolute bottom-3 right-0">
          <button
            className="px-6 py-3 text-white text-opacity-60"
            onClick={logout}
          >
            <svg
              width="14"
              height="12"
              viewBox="0 0 14 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.5898 6.21484C13.8359 5.96875 13.8359 5.55859 13.5898 5.28516L8.99609 0.691406C8.55859 0.28125 7.875 0.582031 7.875 1.15625V3.78125H4.15625C3.77344 3.78125 3.5 4.08203 3.5 4.4375V7.0625C3.5 7.44531 3.77344 7.71875 4.15625 7.71875H7.875V10.3438C7.875 10.9453 8.58594 11.2188 8.99609 10.8086L13.5898 6.21484ZM5.25 10.6719V9.57812C5.25 9.41406 5.08594 9.25 4.92188 9.25H2.625C2.13281 9.25 1.75 8.86719 1.75 8.375V3.125C1.75 2.66016 2.13281 2.25 2.625 2.25H4.92188C5.08594 2.25 5.25 2.11328 5.25 1.92188V0.828125C5.25 0.664062 5.08594 0.5 4.92188 0.5H2.625C1.17578 0.5 0 1.67578 0 3.125V8.375C0 9.82422 1.17578 11 2.625 11H4.92188C5.08594 11 5.25 10.8633 5.25 10.6719Z"
                fill="currentColor"
              />
            </svg>
          </button>
        </div>
      </nav>
    </header>
  )
}

type NavigationLinkProps = {
  label?: string
  icon?: ReactNode
  url?: string
}
export function NavigationLink({
  children,
  label,
  icon,
  url = '/',
}: PropsWithChildren<NavigationLinkProps>) {
  const { condensed } = useNavigation()
  const pathname = usePathname()

  const isActive = url === '/' ? pathname === url : pathname.startsWith(url)

  return (
    <li className="font-light tracking-wide">
      <Link
        href={url}
        aria-label={label}
        title={label}
        className={classNames(
          'flex items-center gap-3 px-2 py-3',
          'text-white hover:text-opacity-100',
          'transition-all duration-300',
          isActive ? 'text-opacity-100' : 'text-opacity-60'
          // condensed.value ? 'px-1' : 'px-2'
        )}
      >
        {/* icon */}
        {icon && (
          <span
            className="flex flex-shrink-0 justify-center"
            style={{ width: 28 }}
          >
            {icon}
          </span>
        )}
        <span
          style={{
            opacity: condensed.value ? 0 : 1,
            transition: 'all 150ms ease',
          }}
          className="transition-all"
        >
          {children}
        </span>
      </Link>
    </li>
  )
}

type NavigationButtonProps = {
  label?: string
  icon?: ReactNode
}
export function NavigationButton({
  label,
  children,
  icon,
  ...props
}: PropsWithChildren<
  NavigationButtonProps & ButtonHTMLAttributes<HTMLButtonElement>
>) {
  const { condensed } = useNavigation()

  return (
    <li className="mt-3 first:my-0">
      <button
        aria-label={label}
        title={label}
        className={classNames(
          'flex gap-3 overflow-hidden',
          'w-full items-center rounded-md bg-white/10 px-2 py-2',
          'whitespace-nowrap font-light tracking-wide text-white',
          'hover:bg-opacity-5',
          'transition-all duration-300',
          'group disabled:pointer-events-none disabled:bg-white/5'
          // condensed.value ? 'px-1' : 'px-2'
        )}
        {...props}
      >
        {icon && (
          <span
            className="flex min-w-0 flex-shrink-0 justify-center text-white/60 group-disabled:opacity-60"
            style={{ width: 28 }}
          >
            {icon}
          </span>
        )}
        <span
          className="min-w-0"
          style={{
            opacity: condensed.value ? 0 : 1,
            transition: 'all 150ms ease',
          }}
        >
          {children}
        </span>
      </button>
    </li>
  )
}
