import { CacheExchangeOpts, NullArray, Resolver, Variables } from '@urql/exchange-graphcache'
import { stringifyVariables } from 'urql'
import { PaginationParams } from '../pages/_app'

import {
  BaseTimesheetDayFragmentDoc,
  BaseTimesheetFragmentDoc,
  BulkApproveTimesheetMutation,
  CreateAbsenceMutation,
  CreateExpenseMutation,
  CreateTimeEntryMutation,
  CreateTimesheetDayMutation,
  DeleteAbsenceMutation,
  DeleteExpenseMutation,
  DeleteTimeEntryMutation,
  MutationBulkApproveTimesheetArgs,
  MutationCreateAbsenceArgs,
  MutationCreateExpenseArgs,
  MutationCreateTimeEntryArgs,
  MutationCreateTimesheetDayArgs,
  MutationDeleteAbsenceArgs,
  MutationDeleteExpenseArgs,
  MutationDeleteTimeEntryArgs,
  MutationSubmitTimesheetArgs,
  MutationUpdateExpenseArgs,
  MutationUpdateTalentAssessmentArgs,
  MutationUpdateTalentStatusArgs,
  MutationUpdateTimeEntryArgs,
  QueryTalentsArgs,
  QueryTimesheetsArgs,
  TalentForVettingFragmentDoc,
  TalentListDocument,
  TalentUpdateStatusMutation,
  TimesheetDayStatus,
  TimesheetsDocument,
  TimesheetSubmitMutation,
  UpdateExpenseMutation,
  UpdateTimeEntryMutation,
} from '@upper/graphql/internal'
import { formatISO } from 'date-fns'

export const urqlCacheExchange: Partial<CacheExchangeOpts> = {
  keys: {
    talentList: null,
  },
  directives: {
    simplePagination,
  },
  resolvers: {
    Query: {
      timesheets: simplePagination({ offsetArgument: 'offset' }),
      talentList: simplePagination({ offsetArgument: 'offset' }),
    },
  },
  optimistic: {
    updateTalentAssessment(
      args: MutationUpdateTalentAssessmentArgs,
      cache,
      info
    ) {
      return {
        __typename: 'TalentAssessment',
        id: args.id,
        status: args.data.status,
        conclusion: args.data.conclusion,
        notes: args.data.notes,
      }
    },
  },
  updates: {
    Mutation: {
      updateTalentStatus: (
        result: TalentUpdateStatusMutation,
        args: MutationUpdateTalentStatusArgs,
        cache,
        info
      ) => {
        const talentFragment = cache.readFragment(
          TalentForVettingFragmentDoc,
          { id: args.id }
        )

        if (talentFragment) {
          cache
            .inspectFields('Query')
            .filter((field) => field.fieldName === 'talentList')
            .forEach((field: { arguments: QueryTalentsArgs }) => {
              console.log(field)

              cache.updateQuery(
                {
                  query: TalentListDocument,
                  variables: field.arguments,
                },
                (data: any | null) => {
                  if (!data) return null
                  if (
                    field.arguments.filters.statuses?.includes(
                      args.data.status
                    )
                  ) {
                    data.talentList?.results?.splice(0, 0, talentFragment)
                    data.talentList.total = data.talentList?.total + 1
                    // alreadyAdded = true
                  } else {
                    data.talentList.results =
                      data.talentList?.results?.filter(
                        (t) => t.status !== talentFragment.status
                      )
                    data.talentList.total = data.talentList?.total - 1
                  }
                  return data
                }
              )
            })
        }
      },
      bulkApproveTimesheet: (
        result: BulkApproveTimesheetMutation,
        args: MutationBulkApproveTimesheetArgs,
        cache,
        info
      ) => {
        cache
          .inspectFields('Query')
          .filter(
            (field: { arguments: QueryTimesheetsArgs; fieldName: string }) =>
              field.fieldName === 'timesheets' &&
              formatISO(new Date(field.arguments.filters.startDate), {
                representation: 'date',
              }) === args.filters.startDate &&
              formatISO(new Date(field.arguments.filters.endDate), {
                representation: 'date',
              }) === args.filters.endDate
          )
          .forEach((field) => {
            const fragment = cache.readQuery({
              query: TimesheetsDocument,
              variables: field.arguments,
            })

            if (!fragment) return
            const days = fragment.timesheets.flatMap((t) => t.days).flat()
            days.forEach((d) => {
              cache.writeFragment(BaseTimesheetDayFragmentDoc, {
                id: d.id,
                status: TimesheetDayStatus.Approved,
              })
            })
          })
      },
      updateTimeEntry: (
        result: UpdateTimeEntryMutation,
        args: MutationUpdateTimeEntryArgs,
        cache,
        info
      ) => {
        const fragment = cache.readFragment(BaseTimesheetDayFragmentDoc, {
          __typename: 'TimesheetDay',
          id: result.updateTimeEntry?.timesheetDayId,
        })
        if (fragment) {
          fragment.totalHours = fragment.timeEntries?.reduce((acc, v) => {
            return (acc += v.hours ?? 0)
          }, 0)
          cache.writeFragment(BaseTimesheetDayFragmentDoc, fragment)
        }
      },
      updateExpense: (
        result: UpdateExpenseMutation,
        args: MutationUpdateExpenseArgs,
        cache,
        info
      ) => {
        const fragment = cache.readFragment(BaseTimesheetDayFragmentDoc, {
          __typename: 'TimesheetDay',
          id: result.updateExpense?.timesheetDayId,
        })
        if (fragment) {
          fragment.totalExpenses = fragment.expenses?.reduce((acc, v) => {
            return (acc += v.amount ?? 0)
          }, 0)
          cache.writeFragment(BaseTimesheetDayFragmentDoc, fragment)
        }
      },
      submitTimesheet: (
        result: TimesheetSubmitMutation,
        args: MutationSubmitTimesheetArgs,
        cache,
        info
      ) => {
        const fragment = cache.readFragment(BaseTimesheetFragmentDoc, {
          __typename: 'Timesheet',
          id: args.engagementId,
        })
        if (fragment) {
          result.submitTimesheet.days?.map((td) => {
            if (!fragment.days?.find((fd) => fd.id === td.id))
              fragment.days?.push(td)
          })
          cache.writeFragment(BaseTimesheetFragmentDoc, fragment)
        }
      },
      createTimesheetDay: (
        result: CreateTimesheetDayMutation,
        args: MutationCreateTimesheetDayArgs,
        cache,
        info
      ) => {
        const fragment = cache.readFragment(BaseTimesheetFragmentDoc, {
          __typename: 'Timesheet',
          id: args.data.engagementId,
        })
        if (fragment) {
          fragment.days?.push(result.createTimesheetDay)
          cache.writeFragment(BaseTimesheetFragmentDoc, fragment)
        }
      },
      createTimeEntry: (
        result: CreateTimeEntryMutation,
        args: MutationCreateTimeEntryArgs,
        cache,
        info
      ) => {
        const fragment = cache.readFragment(BaseTimesheetDayFragmentDoc, {
          __typename: 'TimesheetDay',
          id: args.data.timesheetDayId,
        })
        if (fragment) {
          fragment.timeEntries.push(result.createTimeEntry)
          cache.writeFragment(BaseTimesheetDayFragmentDoc, fragment)
        }
      },
      deleteTimeEntry: (
        result: DeleteTimeEntryMutation,
        args: MutationDeleteTimeEntryArgs,
        cache,
        info
      ) => {
        if (result.deleteTimeEntry)
          cache.invalidate({
            __typename: 'TimeEntry',
            id: args.id,
          })
      },
      createAbsence: (
        result: CreateAbsenceMutation,
        args: MutationCreateAbsenceArgs,
        cache,
        info
      ) => {
        const fragment = cache.readFragment(BaseTimesheetDayFragmentDoc, {
          __typename: 'TimesheetDay',
          id: args.data.timesheetDayId,
        })
        if (fragment) {
          fragment.absences.push(result.createAbsence)
          cache.writeFragment(BaseTimesheetDayFragmentDoc, fragment)
        }
      },
      deleteAbsence: (
        result: DeleteAbsenceMutation,
        args: MutationDeleteAbsenceArgs,
        cache,
        info
      ) => {
        if (result.deleteAbsence)
          cache.invalidate({
            __typename: 'Absence',
            id: args.id,
          })
      },
      createExpense: (
        result: CreateExpenseMutation,
        args: MutationCreateExpenseArgs,
        cache,
        info
      ) => {
        const fragment = cache.readFragment(BaseTimesheetDayFragmentDoc, {
          __typename: 'TimesheetDay',
          id: args.data.timesheetDayId,
        })
        if (fragment) {
          fragment.expenses.push(result.createExpense)
          cache.writeFragment(BaseTimesheetDayFragmentDoc, fragment)
        }
      },
      deleteExpense: (
        result: DeleteExpenseMutation,
        args: MutationDeleteExpenseArgs,
        cache,
        info
      ) => {
        if (result.deleteExpense)
          cache.invalidate({
            __typename: 'Expense',
            id: args.id,
          })
      },
    },
  },
}

function simplePagination({
  offsetArgument = 'offset',
  limitArgument = 'limit',
  mergeMode = 'after',
}: PaginationParams = {}): Resolver<any, any, any> {
  const compareArgs = (
    fieldArgs: Variables,
    connectionArgs: Variables
  ): boolean => {
    for (const key in connectionArgs) {
      if (key === offsetArgument || key === limitArgument) {
        continue
      } else if (!(key in fieldArgs)) {
        return false
      }

      const argA = fieldArgs[key]
      const argB = connectionArgs[key]

      if (
        typeof argA !== typeof argB || typeof argA !== 'object'
          ? argA !== argB
          : stringifyVariables(argA) !== stringifyVariables(argB)
      ) {
        return false
      }
    }

    for (const key in fieldArgs) {
      if (key === offsetArgument || key === limitArgument) {
        continue
      }
      if (!(key in connectionArgs)) return false
    }

    return true
  }

  return (_parent, fieldArgs, cache, info) => {
    const { parentKey: entityKey, fieldName } = info

    const allFields = cache.inspectFields(entityKey)
    const fieldInfos = allFields.filter((info) => info.fieldName === fieldName)

    const size = fieldInfos.length
    if (size === 0) {
      return undefined
    }

    const visited = new Set()
    let result: NullArray<string> = []
    let prevOffset: number | null = null

    for (let i = 0; i < size; i++) {
      const { fieldKey, arguments: args } = fieldInfos[i]
      if (args === null || !compareArgs(fieldArgs, args)) {
        continue
      }

      const link = cache.resolve(entityKey, fieldKey) as string
      const currentOffset = args[offsetArgument]

      if (
        link === null ||
        link.length === 0 ||
        typeof currentOffset !== 'number'
      ) {
        continue
      }

      const tempResult: NullArray<string> = []

      if (!visited.has(link)) {
        tempResult.push(link)
        visited.add(link)
      }

      if (
        (!prevOffset || currentOffset > prevOffset) ===
        (mergeMode === 'after')
      ) {
        result = [...result, ...tempResult]
      } else {
        result = [...tempResult, ...result]
      }

      prevOffset = currentOffset
    }

    const hasCurrentPage = cache.resolve(entityKey, fieldName, fieldArgs)

    if (hasCurrentPage) {
      return result
    } else if (!(info as any).store.schema) {
      return undefined
    } else {
      info.partial = true
      return result
    }
  }
}
