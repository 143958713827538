'use client'
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { TooltipProvider } from '@radix-ui/react-tooltip'
import {
  AuthProvider,
  createUrqlClient,
  WithAuthRedirect,
  WithRoleCheck,
} from '@upper/auth'
import { RoleKey } from '@upper/graphql/auth'
import { ModalsProvider } from '@upper/providers'
import '@upper/sapphire/ui/style'
import { cacheExchange } from '@urql/exchange-graphcache'
import Head from 'next/head'
import Script from 'next/script'
import { Toaster } from 'react-hot-toast'
import { Provider } from 'urql'
import { AddFreelancerDrawer } from '../fragments/freelancers/drawer/add-freelancer'
import { MultiStepManager } from '../fragments/job/multi-step-form/multi-step-manager'
import { urqlCacheExchange } from '../graphql/urql-cache-exchange'
import { NavigationProvider } from '../providers'
import { AccessProvider } from '../providers/access'
import { AppPropsWithAuth } from '../types/app'
import './styles.css'
import { PropsWithChildren } from 'react'

export type MergeMode = 'before' | 'after'

/** Input parameters for the {@link simplePagination} factory. */
export interface PaginationParams {
  /** The name of the field argument used to define the page’s offset. */
  offsetArgument?: string
  /** The name of the field argument used to define the page’s length. */
  limitArgument?: string
  /** Flip between forward and backwards pagination.
   *
   * @remarks
   * When set to `'after'`, its default, pages are merged forwards and in order.
   * When set to `'before'`, pages are merged in reverse, putting later pages
   * in front of earlier ones.
   */
  mergeMode?: MergeMode
}
const APP_NAME = 'internal'

const urqlClient = createUrqlClient(APP_NAME, cacheExchange(urqlCacheExchange))

export default function InternalApp({
  Component,
  pageProps,
  router,
}: AppPropsWithAuth) {
  const getLayout = Component.getLayout ?? ((page) => page)

  return (
    <>
      <Head>
        <link rel="manifest" href="/manifest.json" />
        <meta name="theme-color" content="#0F44D7" />
      </Head>
      <Script
        id="gtm"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${process.env.NEXT_PUBLIC_GTM_INTERNAL}');
          `,
        }}
      />
      <Provider value={urqlClient}>
        <AuthProvider appName={APP_NAME}>
          <WithAuthRedirect
            authenticate={Component.authenticate}
            redirectAuthenticatedTo={Component.redirectAuthenticatedTo}
          >
            <WithRoleCheck roles={RoleKey.Admin}>
              <TooltipProvider>
                <ModalsProvider>
                  <NavigationProvider>
                    <MultiStepManager />
                    <AddFreelancerDrawer />
                    {/* app */}
                    <Head>
                      <title>UPPER - Internal</title>
                    </Head>
                    <main>
                      <AccessProvider
                        restrict={[
                          {
                            path: '/engagements',
                            forRoles: [RoleKey.ExternalExpert],
                          },
                          {
                            path: '/engagements/*',
                            forRoles: [RoleKey.ExternalExpert],
                          },
                          {
                            path: '/timesheets',
                            forRoles: [RoleKey.ExternalExpert],
                          },
                          {
                            path: '/timesheets/*',
                            forRoles: [RoleKey.ExternalExpert],
                          },
                        ]}
                      >
                        {getLayout(<Component {...pageProps} />)}{' '}
                      </AccessProvider>
                    </main>
                  </NavigationProvider>
                </ModalsProvider>
              </TooltipProvider>
            </WithRoleCheck>
          </WithAuthRedirect>
        </AuthProvider>
        <Toaster />
      </Provider>
    </>
  )
}

type ProviderProps = object
export function Providers({ children }: PropsWithChildren<ProviderProps>) {
  return (
    <Provider value={urqlClient}>
      <AuthProvider appName={APP_NAME}>
        {/* <WithAuthRedirect
        // authenticate={Component.authenticate}
        // redirectAuthenticatedTo={Component.redirectAuthenticatedTo}
        > */}
        <WithRoleCheck roles={RoleKey.Admin}>
          <TooltipProvider>
            <ModalsProvider>
              <NavigationProvider>
                <MultiStepManager />
                <AddFreelancerDrawer />
                {/* app */}
                <Head>
                  <title>UPPER - Internal</title>
                </Head>
                <main>
                  <AccessProvider
                    restrict={[
                      {
                        path: '/engagements',
                        forRoles: [RoleKey.ExternalExpert],
                      },
                      {
                        path: '/engagements/*',
                        forRoles: [RoleKey.ExternalExpert],
                      },
                      {
                        path: '/timesheets',
                        forRoles: [RoleKey.ExternalExpert],
                      },
                      {
                        path: '/timesheets/*',
                        forRoles: [RoleKey.ExternalExpert],
                      },
                    ]}
                  >
                    {/* {getLayout(<Component {...pageProps} />)}{' '} */}
                    {children}
                  </AccessProvider>
                </main>
              </NavigationProvider>
            </ModalsProvider>
          </TooltipProvider>
        </WithRoleCheck>
        {/* </WithAuthRedirect> */}
      </AuthProvider>
      <Toaster />
    </Provider>
  )
}
